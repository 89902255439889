<template>
  <div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :top="true"
      :right="true"
      transition="scroll-x-reverse-transition"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <!-- edit profile dialog -->
    <v-card class="user-edit-info pa-sm-10 pa-3">

      <v-card-title class="justify-center text-h5">
        Modification du profil

      </v-card-title>

      <v-card-text class="mt-5">
        <v-form
          ref="profileForm"
          class="multi-col-validation"
        >
          <fieldset class="py-2 m-1">
            <legend class="ml-2 pl-1 pr-1">
              <b>  Contacts </b>
            </legend>
            <div>
              <v-row class='mx-auto'>
                <v-col cols='8'>
                  <v-text-field
                    v-model.trim="userDataLocal.email"
                    outlined
                    dense
                    label="Adesse email"
                    class="mb-4 input"
                    :readonly="activeEmailField"
                    @dblclick="activeEmailField=false"
                    @blur="activeEmailField=true"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn
                  color="primary"
                  @click="updateMail"
                  class='text-capitalize text-center'
                >
                  Modifier
                </v-btn>
                </v-col>
              </v-row>
              <v-row class='mx-auto'>
                <v-col cols='8'>
                  <v-text-field
                    v-model.trim="userDataLocal.phone"
                    :prefix="prefix"
                    outlined
                    dense
                    label="Numéro de téléphone"
                    class="mb-4 input"
                    :readonly="activePhoneField"
                    @dblclick="activePhoneField=false"
                    @blur="activePhoneField=true"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="updatePhone"
                    class='text-capitalize text-center'
                  >
                    Modifier
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </fieldset>
          <fieldset class="py-0 m-1">
            <legend class="ml-2 pl-1 pr-1">
              <b>  Mot de passe  </b>
            </legend>
            <div>
              <v-row class='mx-auto'>
                <v-col cols='8'>
                  <v-text-field
                    v-model="userDataLocal.oldPwd"
                    :type="isPasswordVisible1 ? 'text' : 'password'"
                    label="Ancien mot de passe"
                    :rules="pwdRules"
                    required
                    outlined
                    class="mb-4 input"
                    dense
                    autocomplete="false"
                    hide-details="auto"
                    :append-icon="isPasswordVisible1 ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                    @click:append="isPasswordVisible1 = !isPasswordVisible1"
                  ></v-text-field>
                </v-col>
                <v-col cols='8'>
                  <v-text-field
                    v-model="userDataLocal.password"
                    :error-messages="errors.password_confirmation"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Nouveau mot de passe"
                    :rules="pwdRules"
                    @copy="onCopy"
                    @cut="onCut"
                    @paste="onPaste"
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-4 input"
                    :class="{ 'is-invalid': passwordError, 'is-valid': !passwordError }"
                    @input="checkPassword"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                  <h6
                    v-for='item in regles'
                    v-if='userDataLocal.password && passwordError'
                  >

            <span :class='item.validation?"successMessage":""'
                  style='font-weight: normal'
            >
              {{item.message}}
            </span>
                  </h6>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="updatePassword"
                    class='text-capitalize text-center'
                  >
                    Modifier
                  </v-btn>
                </v-col>
                <v-col cols='8'>
                  <v-text-field
                    v-model="userDataLocal.password_confirmation"
                    :type="isPasswordVisibleConfirm ? 'text' : 'password'"
                    :value="userDataLocal.password_confirmation"
                    :error-messages="errors.password_confirmation"
                    label="Confirmer le mot de passe"
                    :rules="[v => confirmedValidator(userDataLocal.password,userDataLocal.password_confirmation)]"
                    required
                    outlined
                    dense
                    @copy="onCopy"
                    @cut="onCut"
                    @paste="onPaste"
                    hide-details="auto"
                    @input="checkPassword"
                    class="mb-4 input"
                    :disabled="!passwordConditionsMet"
                    :class="{ 'is-invalid': password_confirmationError, 'is-valid': !password_confirmationError }"
                    v-if="!passwordError"
                    :append-icon="isPasswordVisibleConfirm ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                    @click:append="isPasswordVisibleConfirm = !isPasswordVisibleConfirm"
                  ></v-text-field>
                  <div v-if="password_confirmationError">{{password_confirmationError}}</div>
                </v-col>
              </v-row>
            </div>
          </fieldset>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api'
import countries                from '@/@fake-db/data/other/countries'
import Http from '@/helpers/http'
import { mdiEyeOffOutline, mdiEyeOutline, mdiAccount } from '@mdi/js'
import Session from '@/helpers/session'
import router from '@/router'


export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)
    const isPasswordVisible = ref(false)
    const isPasswordVisible1 = ref(false)
    const isPasswordVisibleConfirm = ref(false)

    const activePhoneField = ref(true)
    const activeEmailField = ref(true)
    const password = ref('')
    const password_confirmation = ref('')


    const profileForm = ref(null)
    const errors = ref({})

    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))

    const prefix = ref('+237')

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    const filesAccepted = [
      'image/jpeg', 'image/png', 'image/*',
    ]
    const regles = [
      {
        message:'Le mot de passe doit commencer par une lettre majuscule.',
        validation : true
      },  {
        message:'Le mot de passe doit contenir au moins une lettre minuscule.',
        validation : true
      },  {
        message:'Le mot de passe doit contenir au moins un chiffre.',
        validation : true
      },  {
        message:'Le mot de passe doit contenir au moins un caractère spécial.',
        validation : true
      },{
        message:'Le mot de passe doit contenir au moins 6 caractères.',
        validation : true
      },
    ]

    const snackbar = reactive({
      active: false,
      message: '',
      color: 'success',
      timeout: 15000,
    })

    const pwdRules = [
      v => !!v || 'Le champ est réquis',
      v => (v && v.length <= 20) || 'Le mot de passe doit avoir moins de 20 caractères',
    ]

    const confirmedValidator = (value, target) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      value === target || 'Le mot de passe ne correspond pas'

    const updatePhone = () => {
      let type = 2
      snackbar.show = false
      const phone = ('phone', userDataLocal.value.phone)
      const id = JSON.parse(localStorage.getItem('defaultContrat')).pivot.user_id

      Http.post(`update-user-data/${JSON.parse(localStorage.getItem('defaultContrat')).id}`, { phone, type, id })
        .then(response => {
          profileForm.value.resetValidation()
          userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
          snackbar.value.message = response.message
          snackbar.show = true
          logoutUser()
        }).catch(error => {
          if (error.status === 400) {
            snackbar.message = error.data.errors
          } else if (error.status === 401) {
            snackbar.message = error.data
          } else {
            snackbar.message = 'Votre connexion internet est instable'
          }
          snackbar.color = 'error'
          snackbar.show = true
          console.log(snackbar)
        })
    }

    const updateMail = () => {
      let type = 1
      snackbar.show = false
      const email = ('email', userDataLocal.value.email)
      const id = JSON.parse(localStorage.getItem('defaultContrat')).pivot.user_id
      Http.post(`update-user-data/${JSON.parse(localStorage.getItem('defaultContrat')).id}`, { email, type, id })
        .then(response => {
          profileForm.value.resetValidation()
          userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
          snackbar.value.message = response.message
          snackbar.show = true
          logoutUser()
        }).catch(error => {
          if (error.status === 400) {
            snackbar.message = error.data.errors
          } else if (error.status === 401) {
            snackbar.message = error.data
          } else {
            snackbar.message = 'Votre connexion internet est instable'
          }
          snackbar.color = 'error'
          snackbar.show = true
          console.log(snackbar)
        })
    }

    const updatePassword = () => {
      let type = 3
      snackbar.show = false
      const oldPassword = ('oldPassword', userDataLocal.value.oldPwd)
      const password = ('password', userDataLocal.value.password)
      console.log('ici', oldPassword, password)
      const password_confirmation = ('password_confirmation', userDataLocal.value.password_confirmation)
      const id = JSON.parse(localStorage.getItem('defaultContrat')).pivot.user_id

      Http.post(`update-user-data/${JSON.parse(localStorage.getItem('defaultContrat')).id}`, { oldPassword, password, password_confirmation, type, id })
        .then(response => {
          profileForm.value.resetValidation()
          userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
          snackbar.message = response.message
          snackbar.show = true
          logoutUser()
        }).catch(error => {
          if (error.status === 400) {
            snackbar.message = error.data.errors
          } else if (error.status === 401) {
            snackbar.message = error.data
          } else {
            snackbar.message = 'Votre connexion internet est instable'
          }
          snackbar.color = 'error'
          snackbar.show = true
          console.log(snackbar)
        })
    }

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      Http.post('/sign-out')
        .then(response => {
          Session.unset('accessToken')
          Session.unset('userData')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('contrats')
          localStorage.removeItem('defaultContrat')
          localStorage.removeItem('permissions')
          localStorage.removeItem('defaultRole')
          localStorage.removeItem('menu')
          localStorage.removeItem('roles')
          localStorage.removeItem('periode')

          localStorage.clear()

          router.push({ name: 'auth-login' })
        })
        .catch(error => { console.error('Erreur :', error) })
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      },
    )

    return {
      // showMenu: false,
      passwordChanged: false,
      logoutUser,
      updatePhone,
      updateMail,
      updatePassword,
      isBillingAddress,
      statusOptions,
      languageOptions,
      countries,
      userDataLocal,
      pwdRules,
      password: null,
      password_confirmation: null,
      passwordError: '',
      password_confirmationError: '',
      passwordConditionsMet: false,
      errors,
      regles,
      isPasswordVisible,
      isPasswordVisible1,
      isPasswordVisibleConfirm,
      snackbar,
      profileForm,
      prefix,
      filesAccepted,
      activePhoneField,
      activeEmailField,
      confirmedValidator,

      // eslint-disable-next-line no-undef
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAccount,
      },
    }
  },
  created() {
    this.userDataLocal.password = null
    this.userDataLocal.password_confirmation = null
    this.userDataLocal.oldPwd = null
  },
  methods: {
    checkPassword() {
      let compteur = 0
      this.regles = [
        {
          message:'Le mot de passe doit commencer par une lettre majuscule.',
          validation : false
        },  {
          message:'Le mot de passe doit contenir au moins une lettre minuscule.',
          validation : false
        },  {
          message:'Le mot de passe doit contenir au moins un chiffre.',
          validation : false
        },  {
          message:'Le mot de passe doit contenir au moins un caractère spécial.',
          validation : false
        },{
          message:'Le mot de passe doit contenir au moins 6 caractères.',
          validation : false
        },
      ]
      // Vérifier si le mot de passe respecte les conditions requises
      if (/^[A-Z]/.test(this.userDataLocal.password)) {
        this.passwordError = 'Le mot de passe doit commencer par une lettre majuscule.'
        this.regles[0].validation = true;
        compteur = compteur + 1
      }
      if (/[a-z]/.test(this.userDataLocal.password)) {
        this.passwordError = 'Le mot de passe doit contenir au moins une lettre minuscule.'
        this.regles[1].validation = true;
        compteur = compteur + 1
      }
      if (/\d/.test(this.userDataLocal.password)) {
        this.passwordError = 'Le mot de passe doit contenir au moins un chiffre.'
        this.regles[2].validation = true;
        compteur = compteur + 1
      }
      if (/[\W_]/.test(this.userDataLocal.password)) {
        this.passwordError = 'Le mot de passe doit contenir au moins un caractère spécial.'
        this.regles[3].validation = true
        compteur = compteur + 1
      }
      if (this.userDataLocal.password.length > 6) {
        this.passwordError = 'Le mot de passe doit contenir au moins 6 caractères.'
        this.regles[4].validation = true
        this.passwordConditionsMet = this.userDataLocal.password.length >= 6
        compteur = compteur + 1
      }
      if (compteur === 5){
        this.passwordError =''
      }
      // Vérifier si la confirmation du mot de passe est identique au mot de passe
      if (this.userDataLocal.password !== this.userDataLocal.password_confirmation) {
        this.password_confirmationError = 'Les mots de passe ne correspondent pas.'
      } else {
        this.password_confirmationError = ''
      }
    },
    onCopy(event) {
      event.preventDefault()
    },
    onCut(event) {
      event.preventDefault()
    },
    onPaste(event) {
      event.preventDefault();
    },
  }
}
</script>

<style>
.successMessage {
  color: green !important;
}
.Message {
  color: black !important;
}
.is-invalid {
  border-color: red;
}
.is-valid {
  border-color: green;
}
</style>
